import React from "react";
import userImage from "../../assets/images/about/avatar.jpg";
import {
  FaLinkedinIn, FaDice,
} from "react-icons/fa";
import PageTitle from "../../Share/PageTitle";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <>
      <PageTitle title="Home"></PageTitle>
      {/* End pagetitle */}

      <div
        className="flex flex-col items-center justify-center"
        data-aos="fade"
      >
        <img
          className="rounded-full w-[250px] h-[250px] 2xl:w-[280px] 2xl:h-[280px] mt-32 lg:mt-12  xl:mt-12"
          src={userImage}
          alt=""
        />
        <h3 className="mt-6 mb-1 text-5xl font-semibold dark:text-white">
          Kirsten Ernst
        </h3>
        <p className="mb-4 text-[#7B7B7B]">Dipl. Lern - Dyskalkulietherapeutin / Politikerin</p>
        {/* Avatar Info End */}

        {/* Social information start */}
        <div className="flex space-x-3">
          <a
            href="https://www.lerntherapie-im-aargau.ch/lerntherapeutinnen/kirsten-ernst-dipl-lerntherapeutin-ilt-svlt/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#e14a84] ">
              <FaDice />
            </span>
          </a>
          <a
            href="https://www.linkedin.com/in/kirsten-ernst-326b84153/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="socialbtn text-[#0072b1]">
              <FaLinkedinIn />
            </span>
          </a>
        </div>
        {/* Social information start */}

        <Link className="text-5xl font-semibold" to="/home/contact">
          <button className="flex items-center bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[35px] mt-6">
            Kontaktieren Sie mich
          </button>
        </Link>
        <div className="container lg:rounded-2xl bg-white dark:bg-[#111111]  px-4 sm:px-5 md:px-10 lg:px-20 mt-10 mb-10">
          <div className=" md:mr-12 xl:mr-16">
            <h3 className="text-4xl font-medium dark:text-white mb-2.5 mt-4 ">
              Herzlich willkommen
            </h3>
            <p className="text-gray-lite  dark:text-color-910 leading-7">
              Seit 2017 bin ich Mitglied des Gemeinderats Wettingen.
            </p>
            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
              Am 26. September 2021 habe ich dank Ihnen ein sehr gutes Resultat
              erzielen können und bin als Gemeinderätin im Ressort Tiefbau, Verkehr
              und Umwelt für weitere vier Jahre bestätigt worden. Ich möchte allen, die
              mich unterstützt haben, ganz herzlich danken.
            </p>

            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
              Grosse Herausforderungen stehen vor Wettingen. Das
              Bevölkerungswachstum, die Klimaveränderung, die Bedrohung der
              Artenvielfalt und damit unsere ökologischen Grundlagen. Eine weltweite
              Pandemie hat Teile unserer Gesellschaft im Innersten getroffen.
            </p>
            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
              Als Gemeinderätin will ich weiterhin daran mitwirken, das grosse Potenzial
              von Wettingen auszuschöpfen, damit wir uns in der Region als
              zukunftsorientierte und innovative Gemeinde positionieren können. Nur
              Lösungen, die sowohl sozial-, umwelt- und wirtschaftsverträglich sind,
              bringen unser Wettingen wirklich voran.
            </p>
            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910 ">
              Auf den folgenden Seiten erfahren Sie mehr zu meiner politischen Arbeit
              und zu meiner Person.
            </p>
            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910 mb-4">
              Herzlichen Dank für Ihr Interesse. Ich freue mich auf Ihre Anregungen und
              auf einen konstruktiven Dialog.
            </p>
          </div>
        </div>



      </div>
    </>
  );
};

export default HomePage;

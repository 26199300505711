import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import aboutImage from "../../assets/images/about/about.jpg";
import "../../Share/Style.css";
import Footer from "../../Share/Footer";
import UseData from "../../Hooks/UseData";
import AboutCard from "./AboutCard";
import PageTitle from "../../Share/PageTitle";

const About = () => {
  const { local, experienceArray } = UseData();
  return (
    <>
      <PageTitle title="Über"></PageTitle>
      {/* End pagetitle */}

      <section>
        <div className="container lg:rounded-2xl bg-white dark:bg-[#111111]  px-4 sm:px-5 md:px-10 lg:px-20">
          <div data-aos="fade">
            <div className="py-12">
              {/* Page Title */}
              <h2 className="after-effect after:left-52 mt-12 lg:mt-0 ">
                Über mich
              </h2>
              <div className="grid grid-cols-12 md:gap-10 pt-4 md:pt-[40px]">
                <div className="col-span-12 md:col-span-4">
                  {/* personal images for about page  */}
                  <img
                    className="w-full md:w-[330px] md:h-[400px] object-cover overflow-hidden rounded-[35px] mb-3 md:mb-0"
                    src={aboutImage}
                    alt=""
                  />
                </div>
                <div className="col-span-12 md:col-span-8 space-y-2.5">
                  {/* About me information */}
                  <div className=" md:mr-12 xl:mr-16">
                    <h3 className="text-4xl font-medium dark:text-white mb-2.5 ">
                      Kirsten Ernst
                    </h3>
                    <p className="text-gray-lite  dark:text-color-910 leading-7">
                      Mein Herz und mein Zuhause ist in Wettingen.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      Hier wohne ich mit meiner Familie, hier wuchsen meine beiden Kinder auf
                      und gingen zur Schule, hier arbeite ich in meiner Praxis im Altenburgquartier,
                      hier schöpfe ich Kraft. Meine Familie ist Pfeiler, Anker und Netz – ohne sie
                      wäre mein politisches Engagement nicht möglich.
                    </p>

                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      Wettingen ist vielfältig und gleichermaßen Heimat für Alteingesessene und
                      Zugezogene aus vielen Regionen der Schweiz und Europas, Familien mit
                      Kindern, Alleinlebende, Geflüchtete, Studierende, junge und alte Menschen.
                      Wettingen lebt und pflegt seine drei Leuchttürme; «Sportstadt», «Gartenstadt»
                      und «Generationenstadt». So treffen immer wieder unterschiedliche
                      Sichtweisen aufeinander und es gibt eine rege Diskussionskultur. Auch
                      deshalb bleibt Wettingen für mich so spannend. <br />
                      Meine Heimat ist für mich ein Ort, an dem ich mit den Menschen ins Gespräch
                      komme, über den Markt gehe und geerdet werde.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      Ich entdecke gerne unbekannte Orte im In- und Ausland, geniesse Sport,
                      Spaziergänge rund um Wettingen und die Kameradschaft im Turnverein. In
                      unserem verwunschenen Garten entspanne ich mich bei einem guten Buch
                      oder während der Pflege meiner Rosen. Ich liebe gemeinsames Kochen und
                      die Gespräche mit meiner Familie, Freundinnen und Freunde.
                    </p>
                    <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                      Seit 2017 darf ich Wettingen und seine Menschen im Gemeinderat vertreten
                      und bringe seitdem ihren Blick und ihre Perspektiven in die Politik ein.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="  pb-12 ">
              <div className="grid gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 ">
                {/* Experience information  */}

                {experienceArray.map((item, i) => (
                  <AboutCard key={i} item={item} local={local} />
                ))}
              </div>
            </div>


            {/* Common Footer call here */}
            <Footer condition={false} bg={"#FFFF"} />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

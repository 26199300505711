import React from 'react';
import PageTitle from "../../Share/PageTitle";
import Footer from "../../Share/Footer";

const Work = () => {
    return (
        <div>
            <PageTitle title="Berufliches"></PageTitle>

            <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
                <div data-aos="fade">
                    <div className="container lg:rounded-2xl bg-white dark:bg-[#111111]  px-4 sm:px-5 md:px-10 lg:px-20">
                        <div className="py-12">
                            <h2 className="after-effect after:left-120 mb-[40px] mt-12 lg:mt-0">
                                Berufliches
                            </h2>

                        <div className="col-span-12 md:col-span-8 space-y-2.5">
                            {/* About me information */}
                            <div className=" md:mr-12 xl:mr-16">
                                <p className="text-gray-lite  dark:text-color-910 leading-7">
                                    In meiner bisherigen beruflichen Laufbahn habe ich bereits vieles gemacht.
                                </p>
                                <h3 className="text-4xl font-medium dark:text-white mb-2.5  mt-3">
                                    Traumjob
                                </h3>
                                <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                                    Im Altenburgquartier führe ich als selbständige, diplomierte Lerntherapeutin ilt/SVLT eine eigene Praxis. Ich begleite Kinder, Jugendliche und junge Erwachsene auf ihrem Lernweg. Mein lerntherapeutisches Angebot fokussiert sich seit bald 20 Jahren auf Kinder und Jugendliche mit besonderen Schwierigkeiten in den Teilleistungsbereichen Rechnen (Dyskalkulie), Lesen und Schreiben (Legasthenie).
                                </p>
                                <h3 className="text-4xl font-medium dark:text-white mb-2.5 mt-3">
                                    Verankert im Wettinger Dorf
                                </h3>
                                <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                                    29 Jahre arbeitete ich mit meinem Mann in unserem KMU-Betrieb im Dorfkern von Wettingen. Dort war ich verantwortlich für den Finanz- und Treuhandbereich.
                                </p>
                                <h3 className="text-4xl font-medium dark:text-white mb-2.5 mt-3">
                                    Ausbildung
                                </h3>
                                <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                                    <ul className="list-inside list-disc">
                                        <li>2005 Diplom Lerntherapeutin ilt/SVLT am Institut für Lerntherapie, Schaffhausen, Leitung Dr. phil. Armin Metzger</li>
                                        <li>1989 Diplom Reisefachfrau „International Air Transport Association“ (IATA-UFTAA) an der Kaufm. Handelsschule Aarau</li>
                                        <li>1984 Diplom Med. Praxisassistentin MPA/DVSA Juventus Schulen Zürich</li>
                                    </ul>
                                </p>
                                <h3 className="text-4xl font-medium dark:text-white mb-2.5 mt-3">
                                    Weiterbildungen
                                </h3>
                                <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                                    <ul className="list-inside list-disc">
                                        <li>Kommunikation, Gesprächsführung und Coaching</li>
                                        <li>Konflikt- und Prozessmanagement</li>
                                        <li>Sozial-, Entwicklungs- und Lernpsychologie, sowie in Klinischer Psychologie I, II</li>
                                        <li>Erst- und Zweitspracherwerb bei Kindern im Vorschulalter</li>
                                        <li>Sprachförderung für Erwachsene mit Migrationshintergrund</li>
                                    </ul>
                                </p>
                                <h3 className="text-4xl font-medium dark:text-white mb-2.5 mt-3">
                                    Weiteres
                                </h3>
                                <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                                    <ul className="list-inside list-disc">
                                        <li>Ich bin Kollektiv-Mitglied der Schweizerischen Gesellschaft für Beratung SGfB.</li>
                                        <li>Für den Kanton Aargau begleite ich junge Erwachsene im Bereich Case Management Berufsbildung (Fachstelle Team 1155).</li>
                                        <li>
                                            <a className="hover:text-[#FA5252] duration-300 transition"
                                            href="https://www.lerntherapie.ch"
                                            target="_blank"
                                            rel="noopener noreferrer">
                                                www.lerntherapie.ch
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="hover:text-[#FA5252] duration-300 transition"
                                                href="https://www.lerntherapie-im-aargau.ch"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                www.lerntherapie-im-aargau.ch
                                            </a>
                                        </li>
                                        <li>
                                            <a className="hover:text-[#FA5252] duration-300 transition"
                                                href="https://www.lerntherapie-im-aargau.ch/lerntherapeutinnen/kirsten-ernst-dipl-lerntherapeutin-ilt-svlt/"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                www.lerntherapie-im-aargau.ch/lerntherapeutinnen/kirsten-ernst-dipl-lerntherapeutin-ilt-svlt/
                                            </a>
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        </div>
                    </div>

                    {/* Common Footer call here*/}
                    <Footer condition={true} bg={"#F8FBFB"} />
                </div>
            </section>
        </div>
    );
};

export default Work;
import {useEffect, useState} from "react";
//  icon use as img here
import icon from "../assets/images/icons/icon-1.svg";
import icon1 from "../assets/images/icons/icon-2.svg";
import icon2 from "../assets/images/icons/icon-3.svg";
import icon3 from "../assets/images/icons/icon-4.svg";
import icon4 from "../assets/images/icons/icon-5.svg";
import icon5 from "../assets/images/icons/icon-6.svg";
// contact image
import iconPhone from "../assets/images/contact/phone-call 1.png";
import iconEmail from "../assets/images/contact/email 1.png";
import iconMap from "../assets/images/contact/map 1.png";
import {CgNotes} from "react-icons/cg";
import {FaBlogger, FaRegUser, FaAward} from "react-icons/fa";
import {AiOutlineHome} from "react-icons/ai";
import {FiCodesandbox} from "react-icons/fi";
import {RiContactsBookLine} from "react-icons/ri";
import {Link, useLocation} from "react-router-dom";

import {MdOutlineBusinessCenter, MdOutlineSchool} from "react-icons/md";

const AllData = () => {
    const [check, setCheck] = useState(false);
    const [local, setLocal] = useState(localStorage.getItem("theme"));
    const [singleData, setSingleData] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    // dark and light theme controls
    useEffect(() => {
        const themeValue = localStorage?.getItem("theme");

        if (!themeValue) {
            setCheck(false);
            localStorage.setItem("theme", "light");
        } else {
            themeValue === "dark" && setCheck(true);
            themeValue === "light" && setCheck(false);
        }

        localStorage?.getItem("theme") === "dark"
            ? document.documentElement.classList.add("dark")
            : document.documentElement.classList.remove("dark");
    }, []);

    // Create and light theme function
    const handleTheme = (value) => {
        if (value === "light") {
            setCheck(false);
            localStorage.setItem("theme", "light");
            setLocal("light");
        } else {
            setCheck(true);
            localStorage.setItem("theme", "dark");
            setLocal("dark");
        }
        localStorage?.getItem("theme") === "dark"
            ? document.documentElement.classList.add("dark")
            : document.documentElement.classList.remove("dark");
    };


    // find items for portfilo  modal open
    const handleModelData = (id) => {
        const find = workItems.at(id);
        setSingleData(find);
        setIsOpen(true);
    };


    // Active navlinks function
    function NavLink({
                         to,
                         className,
                         activeClassName,
                         inactiveClassName,
                         ...rest
                     }) {
        let location = useLocation();
        let isActive = location.pathname === to;
        let allClassNames =
            className + (isActive ? `${activeClassName}` : `${inactiveClassName}`);
        return <Link className={allClassNames} to={to} {...rest} />;
    }

    // Elements for protfilo section
    const workItems = [
        {
            title: "Politische Laufbahn und Ämter",
            bg: "#f5d4ff",
            description: [
                "Seit 2009 Mitglied SP Schweiz",
                "2009 – 2017 Einwohnerrätin SP Wettingen",
                "2009 – 2018 Schulpflegerin im Ressort Schulentwicklung und Qualitätssicherung",
                "Seit 2017 Mitglied Vorstand SP Wettingen",
                "Seit 2017 Gemeinderätin im Ressort Tiefbau, Verkehr und Umwelt",
                "Seit 2017 Präsidentin Entsorgungsausschuss",
                "Seit 2017 Präsidentin Friedhofsausschuss",
                "Seit 2017 Viezepräsidentin Vorstand Abwasserverband Region Baden Wettingen ABW",
                "Seit 2017 Vizepräsidentin Vorstand Gemeindeverband Krematorium Region Baden",
                "Seit 2017 Mitglied Stiftungsrat für Gemeindeangestellte- und Lehrerwohnungen",
                "Seit 2017 Mitglied Vorsorgekommission",
                "2017 - 2021 Abgeordnete Gemeindeverband Kehrichtverwertung Baden-Brugg KVA Turgi",
                "Seit 2022 Vorstandsmitglied Gemeindeverband Kehrichtverwertung Baden-Brugg KVA Turgi"
            ],
        },
        {
            title: "Politische Arbeitsgruppen",
            bg: "#ffe0b6",
            description: [
                "Schulentwicklung",
                "Blockzeiten an den Primarschulen in Wettingen",
                "Initiative für ein freiwillig nutzbares und umfassendes Betreuungsangebot für unsere Kinder",
                "Situationsanalyse frühkindliche Bildung, Betreuung und Erziehung in Wettingen (Primokiz)",
                "Umsetzung Tagesstrukturen",
                "Strategie Krippenpool Baden, Ennetbaden, Obersiggenthal und Wettingen",
                "Baukommission Neubau Doppel-Kindergarten mit Tagesstrukturraum im Langäcker",
            ],
        },
        {
            title: "Konzepterarbeitungen der Schule Wettingen",
            bg: "#a7bbff",
            description: [
                "Qualitätskonzept der Schule Wettingen",
                "Vision der Schule Wettingen 2025",
                "Schulprogramm 2015 – 2019",
                "Strategieprozessbegleitung der Schule Wettingen",
                "Elternmitwirkung",
                "Integrierte Heilpädagogik",
                "Deutschkurse für fremdsprachige Mütter",
                "Interkulturelle Vermittlung",
                "Logopädie und Unterricht für Deutsch als Zweitsprache",
                "Integration von fremdsprachigen Schüler:innen",
                "Integrationsstelle Schule Wettingen",

            ],
        },
        {
            title: "Politischen Vorstösse",
            bg: "#c8ffda",
            description: [
                "Offene Turnhallen für Kinder an schulfreien Tagen",
                "Frühförderungskonzept für Kinder im Vorschulalter",
                "Gesamtkonzept für die öffentlichen Toilettenanlagen in Wettingen",
                "Öffnung der Musikschule Wettingen für Erwachsene ab 20 Jahren und für Kinder im Vorschulalter",
                "Familienfreundliches Wettingen – Gutschein-System für die Förderung der Kinder und ihrer Eltern",
            ],
        },
        {
            title: "Engagement",
            bg: "#c8ffda",
            description: [
                "Neben meinem Beruf engagiere ich mich regelmässig ehrenamtlich in verschiedenen Organisationen und Vereinen in Wettingen und im Kanton Aargau.",
                "1998 – 2004 Gründungsmitglied und im Vorstand des Quartiervereins Dorf",
                "2001 – 2008 Präsidentin im Elternrat der Primarschule Altenburg",
                "2009 – 2014 Co-Präsidentin im Lerntherapeutenverband des Kanton Aargau",
                "Mitglied der Insieme Vereinigung Region Baden-Wettingen",
                "Mitglied in den Quartiervereinen Altenburg-Langenstein und Dorf",
                "Mitglied im Damenturnverein Wettingen STV seit bald 30 Jahren",
            ],
        },
    ];
    const [data] = useState(workItems);

    // Menu items for Homepage One
    const menuItem = [
        {
            id: "01",
            name: "Home",
            link: "/home/homePage",
            icon: <AiOutlineHome/>,
        },
        {
            id: "02",
            name: "Über",
            link: "/home/about",
            icon: <FaRegUser/>,
        },
        {
            id: "06",
            name: "Politik",
            link: "/home/politik",
            icon: <CgNotes/>,
        },
        {
            id: "03",
            name: "Berufliches",
            link: "/home/works",
            icon: <FiCodesandbox/>,
        },
        {
            id: "04",
            name: "Kontakt",
            link: "/home/contact",
            icon: <RiContactsBookLine/>,
        },
    ];


    const politikExperiences = [
        {
            id: "0",
            icon: icon5,
            title: "Lerntherapie",
            des: "Im Altenburgquartier führe ich als selbständige, diplomierte Lerntherapeutin ilt/SVLT eine eigene Praxis. Ich begleite Kinder, Jugendliche und junge Erwachsene auf ihrem Lernweg. ",
            color: "#269FFF",
            bg: "#e4f4ff",
        },
        {
            id: "1",
            icon: icon,
            title: "Familienpolitik",
            des: "Eine zeitgemässe Familienpolitik setzt nach meiner Überzeugung beim Wohl der Kinder an. Sie sind die Zukunft unserer Gemeinde.",
            color: "#D566FF",
            bg: "#e7deff",
        },
        {
            id: "2",
            icon: icon1,
            title: "Generationensolidarität",
            des: "Eine vorausschauende Politik ist nachhaltig, sozial und ökologisch.",
            color: "#DDA10C",
            bg: "#fffbe0",
        },
        {
            id: "3",
            icon: icon2,
            title: "Finanzpolitik",
            des: "Sparen und investieren müssen gemeinsam einhergehen.",
            color: "#8774FF",
            bg: "#fce9ff",
        },
        {
            id: "4",
            icon: icon3,
            title: "Bildung",
            des: "Ich mache mich stark für eine qualitativ hochstehende Schul- und Berufsbildung; sie ist der Schlüssel zur gesellschaftlichen Integration und Teilnahme.",
            color: "#FF6080",
            bg: "#ffe5e5",
        },
        {
            id: "5",
            icon: icon4,
            title: "Eine gesunde Umwelt",
            des: "Die Zerstörung unserer Umwelt sollte uns mit Sorge erfüllen.",
            color: "#7eff75",
            bg: "#e2ffe1",
        },
    ];


    // Personal information for contact pages
    const contactArray = [
        {
            id: "01",
            icon: iconPhone,
            title: "Telefon ",
            item1: "078 635 65 02",
            bg: "#FCF4FF",
        },
        {
            id: "02",
            icon: iconEmail,
            title: "Email ",
            item1: "info@kirsten-ernst.ch",
            bg: "#EEFBFF",
        },
        {
            id: "03",
            icon: iconMap,
            title: "Adresse ",
            item1: "Muristrasse 6",
            item2: "5430 Wettingen",
            bg: "#F2F4FF",
        },
    ];

    return {
        handleTheme,
        check,
        local,
        data,
        singleData,
        handleModelData,
        isOpen,
        setIsOpen,
        menuItem,
        experienceArray: politikExperiences,
        contactArray,
        NavLink
    };
};

export default AllData;

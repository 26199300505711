import React from "react";
import UseData from "../../Hooks/UseData";
import Footer from "../../Share/Footer";
import PageTitle from "../../Share/PageTitle";

const Politik = () => {
    const {
        data,
    } = UseData();

    return (
        <>
            <PageTitle title="Politik"></PageTitle>

            <section className="bg-white  lg:rounded-2xl dark:bg-[#111111]">
                <div data-aos="fade">
                    <div className="container lg:rounded-2xl bg-white dark:bg-[#111111]  px-4 sm:px-5 md:px-10 lg:px-20">
                        <div className="py-12">
                            {/*page title here */}
                            <h2 className="after-effect after:left-120 mb-[40px] mt-12 lg:mt-0">
                                Mit Leidenschaft in der Politik
                            </h2>
                            <div className="col-span-12 md:col-span-8 space-y-2.5">
                                {/* About me information */}
                                <div className=" md:mr-12 xl:mr-16">
                                    {data.map((item, i) => (
                                        <div key={i}>
                                            <h3 className="text-4xl font-medium dark:text-white mb-2.5 mt-3">
                                                {item.title}
                                            </h3>
                                            <p className="text-gray-lite leading-7 mt-2.5 dark:text-color-910">
                                                <ul className="list-inside list-disc">
                                                    {item?.description?.map((descItem, i) => (
                                                        <li key={i}>{descItem}</li>
                                                    ))}
                                                </ul>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer condition={false} bg={"#FFFF"}/>
                </div>
            </section>
        </>
    );
};

export default Politik;
